:root {
  --green: #4ecdc4;
  --darkGrey: #292f36;
  --white: #fffffa;
  --black: #000103;
  --orange: #fa8334;
  --red: #ff312e;
}

html {
  box-sizing: border-box;
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Neo Sans", "Neo Sans Pro"
    sans-serif;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /*background-image: url(../images/floating-cogs.svg);*/
  font-family: 'Neo Sans Pro', sans-serif;                                      

  background-color: #ffffff;
  min-height: calc(100vh - 100px);
  margin: 10px;
  background-attachment: fixed;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
