
/*.background {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: 100px auto 50px auto 100px auto;
  grid-template-areas:
    "countdown"
    "logo"
    "title"
    "description"
    "social";
  justify-items: center;
  align-items: center;
  color: var(--white);
}*/

/*.column {
  float: left;
  width: 50%;
  padding: 40px;
}*/

/* Clear floats after the columns 
.row:after {
  content: "";
  display: table;
  clear: both;
}*/

.iconsocial {
  padding: 1px;
  color: #1b7cec;
  width: 40px;
  height: 40px;
}

.iconglo {
  display: flex;
  margin-top: 10px;
  float: right;
  margin-bottom: 2px;
  margin-right: 10px;
}
.iconglo .global {
  color: black;
  text-align: center;
  margin-right: 2px;

  margin-top: 2px;
}
.iconsocial:hover {
  transform: scale(1.1);
}

.description {
  overflow: hidden;
  font-family: 'Dubai', sans-serif;

  margin-top: 10px;
  grid-area: description;
  display: grid;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  grid-template-columns: 1fr;
}
.description-text {
  margin-top: 1px;
}

.joinbutton {
  font-family: 'Dubai', sans-serif;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  background: #1b7cec;
  border: #fff;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  letter-spacing: 0.7px;
  -webkit-box-shadow: 0 15px 10px #e1e6ed;
  -moz-box-shadow: 0 15px 10px #e1e6ed;
  box-shadow: 0 15px 10px #e1e6ed;
}

.joinbutton:hover {
  color: #fff;
  transform: translateY(-7px);
}
.linksSocial{
  margin-top: 20px;
}