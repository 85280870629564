.title {
  grid-area: title;
  display: grid;
  grid-gap: 20px;
  font-family: 'Dubai', sans-serif;
  width: 100%;
  width: 100%;
  max-width: 700px;
  margin: 0px auto;
  color: #000;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.title:before,
.title:after {
  display: block;
  content: "";
  height: 10px;
}

.title:before {
  background: linear-gradient(to left, #1b7cec, transparent);
}

.title:after {
  background: linear-gradient(to right, #1b7cec, transparent);
}
