
.footertext {
  font-size: 12px;
  padding: 2px;
  font-weight: 500;
  font-family: 'Dubai', sans-serif;
  color: #757778;
  position: relative;
  /* negative value of footer height */
  clear: both;
}
