
.logo-container {
    grid-area: logo;
    animation: logo-spin infinite 5s linear;
}

.slow {
  animation-play-state: paused;
}

.fast {
  animation-play-state: running;
}

.logo {
  animation: logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

